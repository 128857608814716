import React, { Component } from 'react';
import axios from 'axios'

export default class AppList extends Component {
    state = {
       arr: [],
       app_summary: {},
       loading: true
      }

   componentDidMount() {

      var instance = axios.create({baseURL: 'https://webropolstatus.azurewebsites.net/api/getstatus'});

      instance.get()
      .then(res => {
         const arr = res.data.arr;
         this.setState({ arr });
         this.setState({ loading: false });

      })
      .catch(error => {
         console.log(error)
      });
   }

   render() {
      return (
         <section className="wrapper">
               <div className="list-group d-flex flex-row flex-wrap">
                  { this.state.arr.map(app =>
                  <div
                     role="alert"
                     key={app.id}
                     className={'list-group-item list-group-item-action flist-group-item ' +app.status}
                  >
                     <div className="d-flex justify-content-between">
                        <h5 className="app-name">{app.name}</h5>
                        <small></small>
                     </div>
                     <small> </small>
                  </div>
                  )}
               </div>
         </section>
      )
   }
}