import React from 'react';
import { Button } from 'react-bootstrap';

const TableHeader = (props) => {
  const { headers } = props;
  const [sortedField, setSortedField] = React.useState(null);
  return(
    <thead className="thead-dark" key="header-1">
        <tr key="header-0">
          { headers && headers.map((value, index) => {
              return <th key={index}><div onClick={() => setSortedField({value})}>{value}</div></th>
          })}
        </tr>
    </thead>
  );
}

export default TableHeader;