import React, { Component } from 'react';
import axios from 'axios'
import { Badge } from 'react-bootstrap';

export default class ApiStatus extends Component {
    state = {
       apps: {},
       arr: [],
       app_summary: {},
       status: '',
       status_text:''
      }

   setStatus(health) {
      if (health = 'green') {
         this.status = 'success'
         this.status_text = 'All Good!'
      } else if (health = 'red') {
         this.status = 'warning'
         this.status_text = 'We might have an issue...'
      } else {
         this.status = 'light'
      }
      console.log(this.status)
   }
   componentDidMount() {
      var instance = axios.create({
         baseURL: 'https://api.eu.newrelic.com/v2/applications.json',
         headers: {'X-Api-Key': 'NRRA-b68b9ce4850b797ff133f94eb3ac40657e816c4e28'}
      })

      instance.get()

      .then(res => {
         const apps = res.data;
         console.log(apps);
         this.setState({ apps });
         const arr = apps.applications;
         this.setState({ arr });
         this.setStatus(arr[12].health_status)
         const status = this.setStatus(arr[12].health_status)
         this.setState({ status })
         console.log(status)
      })
      .catch(error => {
         console.log(error)
      })
   }

   render() {
      return (
         <div className="api-status">
               <Badge variant={this.status}>{this.status_text}
               </Badge>

         </div>
      )
   }
}