
import CookieConsent from "react-cookie-consent";
import React from 'react';
import Emoji from '../common/Emoji'

class Consent extends React.Component {

   render() {
     return (
         <CookieConsent
            location="bottom"
            buttonText="That's cool!"
            cookieName="villDuHaKex"
         >
            <Emoji symbol="🍪" label="cookie" /> <Emoji symbol="🍪" label="cookie" /> We too use cookies to improve the user experience of this site. By clicking you will accept the use of these cookies. <a href="https://www.webropol.co.uk/cookies-on-webropols-website/" target="_blank" rel="noopener noreferrer">Read more...</a> <Emoji symbol="🍪" label="cookie" /><Emoji symbol="🍪" label="cookie" />
         </CookieConsent>

     );
   }
 }

 export default Consent