import React from 'react';
import { Row, Col, Container, Card, Button } from 'react-bootstrap';

class GetStarted extends React.Component {
   render() {
   return (
      <section className="apis" id="apis">
       <Container>
         <Row>
            <Col>
             <h1 className="section-title">Welcome to Our REST API Platform</h1>
            </Col>
         </Row>
         <Row>
            <Col>
            <p>
            With our APIs you can do all the basic features that can be done via the User Interface as well. Get to know our APIs by checking out the endpoints below or read <a href="/docs">the Docs section</a> for additional information.
            </p>
            <p>Our REST API is designed to provide a modern, efficient, and scalable solution for developers and businesses. Leveraging the latest web standards, REST ensures seamless and reliable interactions between systems. Key advantages include:</p>

            <ul>
             <li>Flexibility: Supports data formats like JSON, XML, and CSV for multi-platform compatibility</li>
             <li>Efficiency: Lightweight structure reduces resource usage, ideal for high-performance applications</li>
             <li>Scalability: Stateless design ensures systems can handle increased demand effortlessly</li>
             <li>Secure: Built-in HTTPS and TLS support protect data integrity and confidentiality </li>
            </ul>
            </Col>
         </Row>
         <Row>
            <Col md={{ span: 4, offset: 4 }}>
            <Card className="shadow">
             <Card.Img variant="top" src="images/051-api.png"/>
                <Card.Body>
                 <Card.Title>Webropol REST V1</Card.Title>
                  <Card.Text>
                  RESTful API using JSON format
                  </Card.Text>
                  <span className="d-flex justify-content-center">
                     <Button variant="primary" href="https://documenter.getpostman.com/view/6929240/TVejhqBv#intro" target="_blank">Explore</Button>
                  </span>
                </Card.Body>
             </Card>
            </Col>
         </Row>
         <Row className='mt-4'>
            <Col>
               <p>Discover how REST can transform your projects. Explore our documentation and start building innovative solutions today! </p>
            </Col>
         </Row>
       </Container>
     </section>
   );
   }
 }

 export default GetStarted