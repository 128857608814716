export const faqs = [
   {
      "id": 8,
      "title": "What is a Survey ID?",
      "answer": "Each survey has its own globally unique identifier (GUID) that is represented in an 32 digits with hyphens. Most of the requests will need the survey id in order to work. Your survey id can be found from the URL address when accessing the survey via UI. You can also get all your surveys with their id's by using the request /api/v1/surveys which will list all the surveys your account has access to."
   },
  {
     "id": 1,
     "title": "Why would I benefit from the API?",
     "answer": "Integration is effective: save time, money and resources. Connect your existing customer data to our survey tool or integrate results directly to your systems. Automate your survey sendings and get even more feedback from your customers."
  },
  {
   "id": 2,
   "title": "Is the API free to use?",
   "answer": "Our API can be bought from W-Store when logged in to Webropol as a monthly fee depending on the size of your organization."
   },
   {
   "id": 3,
   "title": "I bought the API module, who does my integration??",
   "answer": "We provide the interface, you develop. We will help you in any issues you might encounter during the way but we do not have resources in external application integration projects."
   },
   {
   "id": 4,
   "title": "I do not read nor comprehend API, where can I get help?",
   "answer": "For starters, you should probably get yourself aquainted with the concept of API's in general. We are happy to help you with any of your issues regarding our API, but general knowledge and usage help needs to be sought elsewhere."
   },
   {
   "id": 6,
   "title": "SOS! It's not working?!",
   "answer": "Have you retested your request several times? Have you checked the syntax? What does the error message say? If you still did not get an answer, please contact our lovely Service Desk. For convenience it is recommended to attach the exact request and response including headers to the email (token excluded). We will also need the username you are using to authenticate to be able to help you as quickly as possible."
   },
   {
   "id": 7,
   "title": "I have an excellent idea about a request that does this and returns that, is that possible!",
   "answer": "Probably YES! We are happy to hear about your ideas on how we could improve our REST API. Please contact us and let us know your needs!"
   }
];