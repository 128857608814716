import React from 'react';
import { codes } from '../docs/data/codes'

class Codeblock extends React.Component {


   render() {
      const which = this.props.codeName;
      const c = codes.find((item) => item.codeName === which)
      const type = this.props.type + ' hljs';

      return(
         <div className="docs-code-block shadow">
         <pre className="shadow-lg rounded">
            <code className={type}>
            {c.lines}
            </code>
         </pre>
      </div>
      );
   }
}

export default Codeblock

