import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Navigation from '../src/components/common/Navigation'
import Footer from '../src/components/common/Footer'
import Home from '../src/components/Home'
import Docs from '../src/components/Docs'
import Status from '../src/components/Status'
import Consent from '../src/components/common/Consent'
import Terms from '../src/components/Terms'
import Monitor from '../src/components/Monitor'
import '../src/styles/styles.css'

class App extends React.Component {

  render() {

    return (
      <div className="App">
      <BrowserRouter>
          <Navigation />
          <Switch>
            <Route path="/" component={Home} exact />
            <Route path="/docs" component={Docs} />
            <Route path="/status" component={Status} />
            <Route path="/cases" render={() => (window.location = "https://documenter.getpostman.com/view/6929240/TVejhqBv")} />
            <Route path="/terminology" component={Terms} />
            <Route path="/monitor" component={Monitor} />
          </Switch>
        </BrowserRouter>

        <Consent />
        </div>
    );
  }

}

export default App;
