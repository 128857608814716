import React from 'react';
import Header from '../components/common/Header'
import Footer from '../components/common/Footer'
import Contact from '../components/common/Contact'
import GetStarted from '../components/home/GetStarted'
import WhyIntegrate from '../components/home/WhyIntegrate'

class Home extends React.Component {
  render() {
    return (
        <div className="home">
           <Header/>

            <GetStarted />
            <WhyIntegrate />
            <Contact />
            <Footer page="home"/>
        </div>

    );
  }

}

export default Home
