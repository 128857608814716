import React from 'react';
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import { Table } from 'react-bootstrap'

const TermTable = (props) => {
  const { headers, rows } = props;
  return (
    <div>
      <Table striped bordered hover responsive size="sm">
        <TableHeader headers={headers}></TableHeader>
        <TableBody headers={headers} rows={rows}></TableBody>
      </Table>
    </div>
  );
}

export default TermTable;