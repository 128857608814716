import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';

class Footer extends React.Component {

   render() {
     const classes = "d-flex justify-content-center " + this.props.page;
     return (
         <footer id="footer" className={classes}>
            <Container>
            <Row className="flex-row flex-wrap">
               <Col md={3}>
               <h5>Contact us</h5>
               <p>Tel. +358 201 552 150</p>
               <p>
               <a href="mailto:sales@webropol.com">sales@webropol.com</a><br/>
               <a href="https://webropol.com">https://webropol.com</a>
               </p>
               <p><small>&copy; Webropol</small></p>
               </Col>
            </Row>
            </Container>
         </footer>
     );
   }
 }

 export default Footer