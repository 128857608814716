export const codes = [
   {
      "codeName": "createEvent",
      "type": "bash",
      "lines": "curl --location --request POST 'https://rest.webropolsurveys.com/api/v1/events'\n \
--header 'Content-Type: application/json'\n \
--header 'Authorization: Bearer your-token' \n \
--data-raw '{\n \
\"EventName\": \"New Event Name\",\n \
\"EventType\": \"Training\",\n \
\"EventStartDate\": \"2020-05-27T16:00:13.842Z\",\n \
\"EventStartTime\": \"2020-05-27T16:00:13.842Z\",\n \
\"EventEndDate\": \"2020-05-27T21:00:45.188Z\",\n \
\"EventEndTime\": \"2020-05-27T21:00:45.188Z\",\n \
\"Venue\": \"Venue Center\",\n \
\"TotalSeats\": 15\n \
}"
   },
   {
      "codeName": "getToken",
      "type": "bash",
      "lines": "curl --location --request POST 'https://rest.webropolsurveys.com/Token'\n \
--header 'Content-Type: xml/json'\n \
--data-urlencode 'grant_type=password'\n \
--data-urlencode 'username=your-webropol-username'\n \
--data-urlencode 'password=your-webropol-password'"
   },
   {
      "codeName": "tokenResponse",
      "type": "bash",
      "lines": "{\n \
   \"access_token\": \"-ffsfsd-PjTRokHOCvvvvvdsdf_Vnk\", \n \
   \"token_type\": \"bearer\",\n \
   \"expires_in\": 14399,\n \
   \"userName\": \"your-webropol-username\",\n \
   \".issued\": \"Wed, 27 May 2020 16:06:34 GMT\",\n \
   \".expires\": \"Wed, 27 May 2020 20:06:34 GMT\"\n \
}"
   },
   {
      "codeName": "getResponses",
      "type": "bash",
      "lines": "curl --location --request GET 'https://rest.webropolsurveys.com/api/v1/surveys/SurveyGUID/answers' \
--header 'Authorization: Bearer your-token'"
   },
   {
      "codeName": "getResponsesWithFilter",
      "type": "bash",
      "lines": "curl --location --request GET 'https://rest.webropolsurveys.com/api/v1/surveys/SurveyGUID/answers' \
--header 'Authorization: Bearer your-token'"
   }
 ];