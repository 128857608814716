import React from 'react';
import { Row, Col } from 'react-bootstrap';

class CommonErrors extends React.Component {

   render() {
     return (
      <article className="docs-article" id="section-intro">
      <header className="docs-header">
          <h1 className="docs-header">Common Errors</h1>
        </header>
         <section className="docs-intro" id="common-errors">
               <Row>
               <Col>
               <p>This section provides some common errors and how to solve them.</p>
               <h2 id="unauthorized">Unauthorized</h2>
               <p>If you get an unauthorized error, it is most likely that your token is invalid or has expired. Please use the <a href="https://staging-rest.webropol.com/swagger/ui/index#/GetToken" target="_blank" rel="noopener noreferrer">token</a> method for creating a new one.</p>

               <h2 id="not-found">404 Not Found</h2>
               <p>This error usually happens when the URL you're trying to request is invalid ie. does not exist. Please check that you haven't got any typos in it or you're not missing a guid.</p>

               <h2 id="internal-server-error">500 Internal Server Error</h2>
               <p>This error might happen due to an invalid request. Please check your request and make sure there are no typos. It is recommended to check the request on another survey/question/etc. to verify the issue is valid. If you can't reproduce it on another setup, you most likely have an issue with your request. If the issue persists, please contact our helpdesk.</p>
               </Col>
               </Row>
         </section>
      </article>
     );
   }
 }

 export default CommonErrors